<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV3.vue'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
 data() {
    return {
      title:'Daftar Unit Layanan Pelanggan',
      dataurl:'/service-center',
      baseroute:'layanan',
      fields:[
        { key: 'code', label: 'KODE', sortable: true},
        { key: 'name', label: 'ULP', sortable: true},
        { key: 'address', label: 'ALAMAT', sortable: true},
        { key: 'leader.name', label: 'PIMPINAN'},
        { key: 'pic.name', label: 'PIC OPERASIONAL'},
      ],
    }
  },
}
</script>
<style lang="">

</style>
